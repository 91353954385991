import { Button } from '@mui/joy';
import { IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react';
import { useState } from 'react';
import Group from './components/Group/Group';
import { useSpotifySdk } from './contexts/SpotifySdkProvider';
import { useDevicesQuery, usePlaybackStateQuery } from './queries/spotify';
import { queryClient } from './queries/queryClient';

function ReloadButton({
  setIsChangingPlayback,
}: {
  setIsChangingPlayback: (value: boolean) => void;
}) {
  return (
    <Button
      onClick={() => {
        setIsChangingPlayback(false);
        queryClient.clear();
      }}
    >
      Reload
    </Button>
  );
}

export default function PlaybackState({ children }: { children: React.ReactNode }) {
  const [isChangingPlayback, setIsChangingPlayback] = useState(false);
  const result = useDevicesQuery();
  const playbackResult = usePlaybackStateQuery();
  const sdk = useSpotifySdk();
  if (result.isLoading) {
    return <>Loading devices...</>;
  }
  if (result.isError) {
    return <>Error loading devices: {result.error.message}</>;
  }
  if (!result.data) {
    return (
      <>
        <p>No devices found</p>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </>
    );
  }

  const device = result.data;
  const deviceId = device.id;

  if (!deviceId) {
    return (
      <>
        <p>No device id found</p>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </>
    );
  }

  const hasPlayContext = playbackResult.data?.item?.name !== undefined;
  const playBackTitle = playbackResult.data?.item?.name || 'Nothing playing';

  console.log('is playing', playbackResult.data?.is_playing);
  return (
    <>
      <Group styles={{ margin: '8px' }}>Wiedergabe auf {device.name}</Group>

      {children}
      <Group>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </Group>
      {hasPlayContext && (
        <>
          <div style={{ height: '90px' }} />
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              backgroundColor: 'white',
              width: '100%',
              padding: '8px 0px',
              borderTop: '1px solid #e0e0e0',
              transform: 'translate3d(0,0,0)',
            }}
          >
            <Group>
              {playbackResult.data?.is_playing ? (
                <Button
                  variant="solid"
                  size="md"
                  loading={isChangingPlayback}
                  onClick={async () => {
                    setIsChangingPlayback(true);
                    try {
                      await sdk.player.pausePlayback(deviceId);
                      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
                      window.setTimeout(() => {
                        setIsChangingPlayback(false);
                      }, 1000);
                    } catch (e) {
                      console.log(e);
                      setIsChangingPlayback(false);
                    }
                  }}
                >
                  <IconPlayerPause />
                </Button>
              ) : (
                <Button
                  variant="solid"
                  size="md"
                  loading={isChangingPlayback}
                  onClick={async () => {
                    setIsChangingPlayback(true);
                    try {
                      await sdk.player.startResumePlayback(deviceId);
                      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
                      window.setTimeout(() => {
                        setIsChangingPlayback(false);
                      }, 1000);
                    } catch (e) {
                      console.log(e);
                      setIsChangingPlayback(false);
                    }
                  }}
                >
                  <IconPlayerPlay />
                </Button>
              )}
            </Group>
            <div style={{ height: '8px' }} />
            <Group>{playBackTitle}</Group>
          </div>
        </>
      )}
    </>
  );
}
