import { Scopes } from '@spotify/web-api-ts-sdk';
import { QueryClientProvider } from '@tanstack/react-query';
import { Router } from './Router';
import { SpotifySdkProvider } from './contexts/SpotifySdkProvider';

import PlaybackState from './PlaybackState';
import { queryClient } from './queries/queryClient';

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SpotifySdkProvider
        clientId={import.meta.env.VITE_SPOTIFY_CLIENT_ID}
        redirectUrl={import.meta.env.VITE_REDIRECT_TARGET}
        scopes={[...Scopes.userDetails, ...Scopes.playlist, ...Scopes.userPlayback]}
      >
        <PlaybackState>
          <Router />
        </PlaybackState>
      </SpotifySdkProvider>
    </QueryClientProvider>
  );
}
