import { CSSProperties } from 'react';

export default function Group({
  children,
  styles = {},
}: {
  children: React.ReactNode;
  styles?: CSSProperties;
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '12px',
        alignItems: 'center',
        ...styles,
      }}
    >
      {children}
    </div>
  );
}
