import { useParams } from 'react-router-dom';
import ArtistDetails from '../components/ArtistDetails/ArtistDetails';

export function Artist() {
  const { id } = useParams();

  return (
    <>
      <div>{id && <ArtistDetails id={id} />}</div>
    </>
  );
}
