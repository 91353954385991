import { Album, Artist, SpotifyApi } from '@spotify/web-api-ts-sdk';
import { useQuery } from '@tanstack/react-query';
import { Batcher, create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { useSpotifySdk } from '../contexts/SpotifySdkProvider';

let artistsBatcher: Batcher<Artist[], string, Artist> | null = null;

const getArtistBatcher = (sdk: SpotifyApi) => {
  if (artistsBatcher) {
    return artistsBatcher;
  }
  artistsBatcher = create({
    name: 'artists',
    fetcher: async (artistIds: string[]) => sdk.artists.get(artistIds),
    resolver: keyResolver('id'),
    scheduler: windowScheduler(10),
  });
  return artistsBatcher;
};

export const useArtistQuery = (artistId: string) => {
  const sdk = useSpotifySdk();
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['artists', artistId],
    queryFn: () => sdk && getArtistBatcher(sdk).fetch(artistId),
  });
};

export const useArtistAlbumsQuery = (artistId: string) => {
  const sdk = useSpotifySdk();
  return useQuery({
    queryKey: ['artist-albums', artistId],
    queryFn: async () => {
      const albums: Album[] = [];
      const albumResult = await sdk.artists.albums(artistId, undefined, undefined, 50);
      albumResult.items.forEach((item) => albums.push(item as unknown as Album));
      // Substract the page we already got at the end
      const maxPages = Math.min(Math.ceil(albumResult.total / 50), 10) - 1;
      // Create sequence starting by 50 and incrementing by 50 until we reach the max
      const sequence = Array.from(Array(maxPages).keys()).map((i) => (i + 1) * 50);
      console.log('sequence', sequence);
      console.log('albumresult', albumResult);
      // Fetch all pages in parallel
      const otherItems = await Promise.all(
        sequence.map(async (offset) => {
          const result = await sdk.artists.albums(artistId, undefined, undefined, 50, offset);
          return result.items;
        })
      );
      // Flatten the array of arrays
      const otherAlbums = otherItems.flat() as unknown as Album[];
      return albums.concat(otherAlbums);
    },
  });
};

export const useDevicesQuery = () => {
  const sdk = useSpotifySdk();
  return useQuery({
    queryKey: ['devices'],
    queryFn: async () => {
      const result = await sdk.player.getAvailableDevices();
      if (!result) {
        return null;
      }
      if (result.devices.length === 0) {
        return null;
      }
      const phone = result.devices.find((d) => d.name.toLocaleLowerCase().includes('phone'));
      if (phone) {
        return phone;
      }
      return result.devices[0];
    },
  });
};

export const usePlaybackStateQuery = () => {
  const sdk = useSpotifySdk();
  return useQuery({
    queryKey: ['playback-state'],
    queryFn: () => {
      console.log('Refetching playback state');
      return sdk.player.getPlaybackState();
    },
    refetchInterval: 3000,
  });
};
