import { Button, Card } from '@mui/joy';
import { Link } from 'react-router-dom';
import artists from '../../data/artists.json';
import { useArtistQuery } from '../../queries/spotify';
import Group from '../Group/Group';

function ArtistCard({ artistId }: { artistId: string }) {
  const result = useArtistQuery(artistId);
  if (result.isLoading) return null;
  if (result.isError) return null;
  const artist = result.data;
  if (!artist) return null;
  return (
    <Card
      key={artist.id}
      sx={{ marginBottom: '12px' }}
      component={Link}
      to={`artist/${artist.id}`}
      style={{ textDecoration: 'none' }}
    >
      <Group>
        <img src={artist.images[0].url} alt={artist.name} height={150} width={150} />
      </Group>
      <Group>{artist.name}</Group>
    </Card>
  );
}

function ArtistsLists() {
  return (
    <>
      {artists.map((artist) => (
        <ArtistCard artistId={artist.id} key={artist.id} />
      ))}
    </>
  );
}

export default ArtistsLists;
