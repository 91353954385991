import { SdkOptions, SpotifyApi } from '@spotify/web-api-ts-sdk';
import { createContext, useContext } from 'react';
import { useSpotify } from '../hooks/useSpotify';

// Define the context
const SpotifySdkContext = createContext(null as unknown as SpotifyApi);

// Define the provider
type SpotifySdkProviderProps = {
  clientId: string;
  redirectUrl: string;
  scopes: string[];
  config?: SdkOptions;
  children: React.ReactNode; // Add the correct type for children
};

export function SpotifySdkProvider({
  clientId,
  redirectUrl,
  scopes,
  config,
  children,
}: SpotifySdkProviderProps) {
  const sdk = useSpotify(clientId, redirectUrl, scopes, config);
  if (!sdk) return null;
  return <SpotifySdkContext.Provider value={sdk}>{children}</SpotifySdkContext.Provider>;
}

// Define a hook for easy access to the context
export function useSpotifySdk() {
  return useContext(SpotifySdkContext);
}
