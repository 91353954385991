import ArtistsLists from '../components/ArtistsList/ArtistsList';

export function HomePage() {
  return (
    <>
      <ArtistsLists />
      {/* <SpotifySearch sdk={sdk} /> */}
    </>
  );
}
